import { Controller } from "stimulus"
import { initFlatpickr } from '../components/init_flatpickr';
import { enableEndDate } from '../components/enable_end_date';
import { userFormsValidations } from '../components/user_forms_validations';

export default class extends Controller {
  static targets = [ "links", "template" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
  }

  add_association(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
    enableEndDate();
    initFlatpickr();
    userFormsValidations();
  }

  remove_association(event) {
    event.preventDefault();

    let wrapper = event.target.closest(".nested-fields");
    let destroyInput = wrapper.querySelector("input[name*='_destroy']");

    if (destroyInput) {
      // Mark the record for deletion instead of just removing from DOM
      destroyInput.value = "1";
    }

    // Hide the field instead of removing it so Rails detects _destroy = true
    wrapper.style.display = "none";
  }
}
