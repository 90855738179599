const userFormsValidations = () => {
  const userForms = document.querySelectorAll('.user-creation-form')

  const handleValidation = (form) => {
    const inputs = form.querySelectorAll('input')
    if (
      inputs[1].value !== "" &&
      inputs[2].value !== "" &&
      inputs[3].value.match(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[6-7](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/)
    ) {
      form.style.border = "3px solid green"
    } else {
      form.style.border = "3px solid red"
    }
  }

  userForms.forEach(form => {
    form.addEventListener('keyup', () => {
      handleValidation(form)
    })
    form.addEventListener('click', () => {
      handleValidation(form)
    })
  })
}

export { userFormsValidations }
