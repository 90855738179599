require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

// External imports
import "bootstrap";
import "flatpickr/dist/themes/dark.css";

// Internal imports, e.g:
import "controllers"
import { displayBlocks } from '../components/display_blocks.js';
import { checkSiret } from '../components/check_siret.js';
import { initSelect2 } from '../components/init_select2';
import { enableEndDate, enableUserEndDate } from '../components/enable_end_date';
import { initFlatpickr } from '../components/init_flatpickr';
import { userFormsValidations } from '../components/user_forms_validations';

document.addEventListener('turbolinks:load', () => {
  displayBlocks();
  checkSiret();
  initSelect2();
  enableUserEndDate();
  enableEndDate();
  initFlatpickr();
  userFormsValidations();

  const input = document.querySelector('.email-to-clean')
  if (input) {
    input.value = ''
  }

  $('#hamburger').click(function() {
    $('#hamburger').toggleClass('show');
    $('#overlay').toggleClass('show');
    $('.nav').toggleClass('show');
  });
});
